import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import moment from 'moment'
import * as mime from 'mime-types'
import ENV_CONFIG from '@/config/environment'
import faxSubmission from './modules/faxSubmission'
import reporting from './modules/reporting'
import displayMessages from './modules/UMSMessages'
import customerLogos from './modules/customerLogos'
import browserRefresh from './modules/browserRefresh'
import attestation from './modules/attestation'
import gorilla from './modules/gorilla'
import deepFreeze from '../mixins/deepFreeze'
import socketConnection from './modules/socketConnection'
import submission from './modules/submission'
import medicalNecessity from './modules/medicalNecessity'
import medicalNecessityLegacy from './modules/medicalNecessityLegacy'
import auth from './modules/auth'
import worklist from './modules/worklist'
import dataIngest from './modules/dataIngest'
import downloadQueue from './modules/downloadQueue'
import archive from './modules/archive'
import PaginatedFilterMapping from '@/services/paginatedFilterMapping'
import logger from '../shared/logger'
import { Filter, Sort } from '@/shared/models/worklist'
import { WorklistService } from '@/services/worklist'
import { UrgencyType } from '@/shared/models/userAssignment'
import { directSubmitHealthHelp }  from './modules/directSubmit/healthHelp'

Vue.use(Vuex)

const apiPath1 = ENV_CONFIG.env_api;
window.console.log(ENV_CONFIG.env_name + " " + ENV_CONFIG.env_api);

function initialState() {
    return {
        tokenExpires: 3600,
        timerInterval: 30000,
        workers: [],
        availableRoles: false,
        requests: [],
        archiveRequest: [],
        allRequestsCount: 0,
        allArchiveRequestsCount: 0,
        textSearchTerm: WorklistService.processSearchTerm(
            sessionStorage.getItem('textSearchTerm')
        ),
        paSearchTerm: false,
        filteredPaRequestIdArray: sessionStorage.getItem('worklistPaRequestIdArray') || [],
        upcomingReleaseNotes: false,
        worklistCurrentFilters: [],
        worklistNotifications: [],
        worklistDisplaySettings: null,
        worklistCurrentPage: 0,
        archiveCurrentPage: 1,
        showAllDepartmentRequests: false,
        loggedIn: false,
        twoFAMethods: false,
        fetchingRequests: true,
        dataList: {
            Roles: [],
            Status: [],
            UrgentEnum: [],
            Workers: []
        },
        departments: [],
        departmentFollowUpDateRules: [],
        isRequestLoadingForDeptFollowUpRules: false,
        hospitalNetwork: null,
        stempList: false,
        emrOrderingDepartments: [],
        servicingFacilityValues: [],
        currUser: false,
        showReleaseNotes: false,
        userNotifications: [],
        recentlyViewedRequests: [],
        loadingRecentlyViewedRequests: false,
        linkedAccounts: false,
        portalLogins: false,
        documentClasses: false,
        showPayerModal: false,
        showGenerateModal: false,
        showDocumentModal: false,
        modalBlobDocumentId: false,
        modalData: {},
        currentTabChanges: false,
        showUnsavedModal: false,
        requestComments: [],
        requestFormData: false,
        globalMessage: {
            message: '',
            type: 'error',
            clear: 'timer',
            show: false
        },
        uploadProgress: 0,
        providers: false,
        portalList: false,
        payerList: false,
        payerNotes: false,
        paAttachedFiles: false,
        paSuggestedFiles: false,
        documentUrls: false,
        documentPreviews: false,
        paRequestAndFormData: false,
        hasRelatedPas: false,
        paRequestForm: false,
        paEligibility: false,
        paRequestOverview: false,
        paRequestCompletion: false,
        paLastViewDate: false,
        paEmrDifferences: false,
        paEventFeed: [],
        paEventFeedLoading: false,
        paEventFeedNoteChanges: false,
        paAppointmentStatus: null,
        paSubmission: [],
        submissionStatusesAndReasonCodes: false,
        submissionMethods: false,
        facilities: [],
        submissionStatusCategories: [],
        submissionHistory:[],
        paPrintStatus: {},
        paFormCompanies: false,
        socketUpdateToPa: false,
        worklistSortInfo: WorklistService.transformSortsToNewFormat("worklist"),
        archiveSortInfo: WorklistService.transformSortsToNewFormat("archive"),
        reportGroups: null,
        reportList: null,
        reportHTML: '',
        lastActiveReportsTab: 0,
        fallbackToPolling: false,
        customerSettings: false,
        userProfileChanges: false,
        userCredentialChanges: false,
        browserIsIE: false,
        portalAssistant: {
            displayAssistant: false,
            requestId: null,
            insuranceMemberId: null,
            insuranceGroup: null,
            payerType: null,
            portal: null
        },
        directSubmitWindow: {
            displayWindow: false,
            submission: null,
            paRequestOverview: null,
            qStatus: 0,
            submissionInProgress: false,
            minimized: false
        },
        canViewPcpForms: false,
        systemFilters: [],
        customUserFilters: [],
        userCounts: [],
        /**
         * 301 = Archived
         */
        hiddenStatuesFromWorklist: [301],
    }
}

const store = new Vuex.Store({
    state: initialState,
    modules: { 
        faxSubmission, 
        reporting, 
        displayMessages, 
        browserRefresh, 
        attestation, 
        gorilla, 
        customerLogos, 
        socketConnection,
        submission,
        medicalNecessity,
        medicalNecessityLegacy,
        auth,
        worklist,
        dataIngest,
        downloadQueue,
        archive,
        directSubmitHealthHelp
    },
    actions: {
        GET_LINKED_ACCOUNTS: async function({ commit }) {
            await axios.get(`${apiPath1}Auth/GetLinkedAccounts`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_LINKED_ACCOUNTS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        SWITCH_TO_LINKED_ACCOUNT: async function({ commit, dispatch, state }, paPersonId) {
            sessionStorage.removeItem('worklistFilter');
            sessionStorage.removeItem('worklistColumns');
            await axios.post(`${apiPath1}auth/SwitchToLinkedAccount/${paPersonId}`, paPersonId, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    sessionStorage.setItem('token', response.data.token)
                    sessionStorage.setItem('expiration', response.data.expiration);
                    sessionStorage.setItem('currUserId', response.data.paPersonId);
                    sessionStorage.setItem('customerId', response.data.customerId)
                    const s = initialState()
                    Object.keys(s).forEach(key => {
                        state[key] = s[key]
                    })
                    window.location.href = '/' // intentionally hard redirect to avoid receiving unresolved requests from old user
                }).catch(error => {
                    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
                        dispatch('auth/SIGN_OUT')
                        commit('SET_GLOBAL_MESSAGE', ['error', `'Error switching accounts. ${error.response.data}`])
                        return
                    }
                    if (window.location.protocol === 'http:') {
                        commit('SET_GLOBAL_MESSAGE', ['error', `${error.message}. Unsecure connection, you must use HTTPS`])
                        return
                    }
                    commit('SET_GLOBAL_MESSAGE', ['error', `Unknown ${error.message}. Check that you are connected to the internet, using HTTPS and that your network IP is whitelisted.`])

                })
        },
        GET_PORTAL_LOGINS: async function({ commit, state, dispatch }, suppressGlobalMessage) {
            await axios.get(`${apiPath1}Profile/GetPortalLogins`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then(response => {
                    commit('SET_PORTAL_LOGINS', response.data)
                    let invalid = response.data.filter(p => !p.isValid).filter(p => (p.userName && p.password))
                    if (invalid.length && !suppressGlobalMessage && !state.currUser.listOfRoles.includes('ReadOnly')) {
                    dispatch('ADD_USER_NOTIFICATION', {
                        message: `Portal Credential Issue`,
                        destination: `UserProfile`,
                        title: `Your Status Check Credentials are out of date.`,
                        when: Date.now()
                      })
                        commit('SET_GLOBAL_MESSAGE', ['error', `<i class="fa fa-exclamation-triangle"></i> Your Status Check Credentials are out of date. <span>Update Credentials</span>`, null, 'UserProfile'])
                    }
                }, (err) => {
                    if (err.response.status === 401) this.$store.dispatch('auth/SIGN_OUT')
                    commit('SET_PORTAL_LOGINS', false)
                })
        },
        ADD_USER_NOTIFICATION: function({ commit, state }, notification){
            let duplicationCheck = state.userNotifications.filter(n => n.destination == notification.destination && n.title == notification.title)
                if (duplicationCheck.length == 0) {
                    let notifications = [...state.userNotifications, notification]
                    commit('SET_USER_NOTIFICATIONS', notifications)
                    this.dispatch('SET_USER_NOTIFICATIONS_IN_TEMP_STORAGE', JSON.stringify(notifications))
                }
        },
        REMOVE_USER_NOTIFICATION: function ({ commit, state }, timestamp) {
            let notifications = state.userNotifications.filter(n => n.when !== timestamp)
            commit('SET_USER_NOTIFICATIONS', notifications)
            this.dispatch('SET_USER_NOTIFICATIONS_IN_TEMP_STORAGE', JSON.stringify(notifications))
        },
        SET_USER_NOTIFICATIONS_IN_TEMP_STORAGE: async function({state}, notificationsString) {
            let params = {
                paPersonId: state.auth.currUserID,
                userNotificationJsonArray: notificationsString
            }
            axios.post(`${apiPath1}WorkDashboard/UpdateTempStorage`, params, {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
            })
        },
        GET_USER_NOTIFICATIONS_IN_TEMP_STORAGE: async function({commit}){
            await axios.get(`${apiPath1}WorkDashboard/GetTempStorage`, {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
            })
                .then((response) => {
                    commit('SET_USER_NOTIFICATIONS', JSON.parse(response.data.userNotificationJsonArray))
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', err.response.data])
                })
        },
        GET_RECENTLY_VIEWED_REQUESTS: function ({commit}) {
            commit('SET_LOADING_RECENTLY_VIEWED_REQUESTS', true)
            
            window.setTimeout(() => {
                axios.get(`${apiPath1}PaRequests/GetRecentlyViewedRequests`, {
                    headers: { 
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                    .then((res) => {
                        commit('SET_RECENTLY_VIEWED_REQUESTS', res.data)
                        commit('SET_LOADING_RECENTLY_VIEWED_REQUESTS', false)
                    }, (err) => {
                        if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                        commit('SET_RECENTLY_VIEWED_REQUESTS', false)
                        commit('SET_LOADING_RECENTLY_VIEWED_REQUESTS', false)
                    })
            }, 100)
        },
        GET_TOKEN_EXPIRE_TIME: function({ commit, dispatch }) {
            const expires = sessionStorage.getItem('expiration')
            const diff = moment(expires).diff(moment(moment().utc()))
            const secsToExpire = Math.floor(diff * 0.001)
            if (secsToExpire < 60) commit('auth/SET_TIMER_INTERVAL', 1000)
            if (secsToExpire < 1) {
                dispatch('auth/SIGN_OUT')
                commit('SET_GLOBAL_MESSAGE', ['error', 'Session Expired. Please Log In Again'])
            }
            commit('SET_TOKEN_EXPIRES', secsToExpire)
        },
        GET_UPCOMING_RELEASE_NOTES: async function({commit}) {
            axios.get(`${apiPath1}WorklistVersion/GetWorklistVersion/2`, {
                headers: { 
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
                .then((res) => {
                    commit('SET_UPCOMING_RELEASE_NOTES', res.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_UPCOMING_RELEASE_NOTES', false)
                })
        },
        MANAGER_DEACTIVATE_USER: async function({ commit, dispatch }, paPersonId) {
            await axios.put(`${apiPath1}WorkerDashboardManager/ManagerDeactivateUser/${paPersonId}`, paPersonId, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    commit('SET_GLOBAL_MESSAGE', ['error', err.response.data])
                })
        },
        MANAGER_ACTIVATE_USER: async function({ commit, dispatch }, paPersonId) {
            await axios.put(`${apiPath1}WorkerDashboardManager/ManagerReactivateUser/${paPersonId}`, paPersonId, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        MANAGER_BULK_REASSIGN_REQUESTS: async function({ commit, dispatch }, params) {
            await axios.put(`${apiPath1}WorkerDashboardManager/BulkReassignActiveRequests`, params, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        MANAGER_ASSIGN_ROLES: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}WorkerDashboardManager/AssignRoles`, params, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_WORKERS', { workers: response.data })
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    if (err.response) commit('SET_GLOBAL_MESSAGE', ['error', `${err.response.data}`])
                    window.console.log(err)
                })
        },
        MANAGER_ASSIGN_DEPTS: async function ({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}WorkerDashboardManager/AssignDepartments `, params, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
                .then((response) => {
                    commit('SET_WORKERS', { workers: response.data })
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    if (err.response) commit('SET_GLOBAL_MESSAGE', ['error', `${err.response.data}`])
                    window.console.log(err)
                    return Promise.reject(new Error('changeDept'))
                })
        },
        GET_AVAILABLE_ROLES: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}WorkerDashboardManager/GetAvailableRoles`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_AVAILABLE_ROLES', { roles: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_WORKERS_NO_CACHE: async function({ commit, dispatch }) {
            await axios.get(apiPath1 + 'WorkDashboard/GetWorkersWithoutCache', {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_WORKERS', { workers: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })

        },
        GET_WORKERS: async function({ commit, dispatch }) {
            await axios.get(apiPath1 + 'WorkDashboard/GetWorkers', {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_WORKERS', { workers: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })

        },
        UPDATE_SINGLE_WORKER_EMAIL: function({ state, commit }, params) {
            let index = state.workers.findIndex(w => w.paPersonId === params.paPersonId)
            let worker = state.workers.find(w => w.paPersonId === params.paPersonId)
            if (index > -1) {
                let updatedWorker = Object.assign({}, worker, params)
                commit('SET_UPDATED_WORKER_IN_ALL_WORKERS', { index: index, worker: updatedWorker })
            }
        },
        UPDATE_FOLLOW_UP_DATE_RULES: function({state, commit}, params) {
            commit('SET_DEPT_RULES_LOADING_FLAG', true);
            axios.post(`${apiPath1}WorkerDashboardManager/UpdateDepartmentFollowUpDateRules`, params, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    Pragma: 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_DEPARTMENT_FOLLOW_UP_DATE_RULES', { departmentFollowUpDateRules: response.data });
                commit('SET_DEPT_RULES_LOADING_FLAG', false);
                commit('SET_GLOBAL_MESSAGE', ['success', 'Follow Up date rules saved successfully']);
            })
            .catch(error => {
                commit('SET_DEPT_RULES_LOADING_FLAG', false);
                window.console.log(error.response.status);
                if (error.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                    router.push({ path: '/SignIn' });
                }
                if (error.response.status === 500) {
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to connect to server']);
                }
            })
        },
        LOAD_REQUESTS: function({ commit }) {
            commit('SET_FETCHING_REQUESTS')
        },
        GET_ALL_REQUESTS: async function({ state, commit, dispatch }) {
            let dept = !state.showAllDepartmentRequests
            await axios.get(`${apiPath1}WorkDashboard/GetAllRequests?currDepartmentOnly=${dept}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_ALL_REQUESTS', { requests: response.data.requests })
                    commit('SET_WORKLIST_NOTIFICATIONS', response.data.notificationFlags)
                    commit('SET_WORKLIST_DISPLAY_SETTINGS', response.data.worklistDisplaySettings)
                })
                .catch(error => {
                    window.console.log(error.response.status)
                    if (error.response.status === 401) {
                        commit('auth/SIGN_OUT')
                        router.push({ path: '/SignIn' })
                    }
                    if (error.response.status === 500) {
                        commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to connect to server'])
                    }
                })

        },
        GET_PAGINATED_REQUESTS: async function({ commit, getters }) {
            commit('SET_FETCHING_REQUESTS')

            await axios.post(`${apiPath1}WorkDashboard/GetRequests`, getters.worklistParams, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                const reverseEngineerFilters = PaginatedFilterMapping.reverseEngineerFilters({ filter: JSON.stringify(getters.worklistParams) })
                const parsedFilters = JSON.parse(reverseEngineerFilters.filter)

                if (Object.keys(parsedFilters).length > 0) {
                    commit('SET_PA_SEARCH_TERM', JSON.parse(reverseEngineerFilters.filter))
                } else {
                    commit('SET_PA_SEARCH_TERM', false)
                }

                commit('SET_ALL_REQUESTS_COUNT', response.data.count )
                commit('SET_ALL_REQUESTS', { requests: response.data.requests })
                commit('SET_WORKLIST_NOTIFICATIONS', response.data.notificationFlags)
                commit('SET_WORKLIST_DISPLAY_SETTINGS', response.data.worklistDisplaySettings)
            })
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    commit('auth/SIGN_OUT')
                    router.push({ path: '/SignIn' })
                }
                if (error.response.status === 500) {
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to connect to server'])
                }
            })
        },
        SEARCH_ARCHIVED_REQUESTS: async function({ commit, getters }) {
            await axios.post(`${apiPath1}WorkDashboard/GetArchivedRequests`, getters.archiveParams, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_ALL_ARCHIVE_REQUESTS_COUNT', response.data.count )
                    commit('SET_ALL_ARCHIVE_REQUESTS', { requests: response.data.requests })
                    commit('SET_WORKLIST_NOTIFICATIONS', response.data.notificationFlags)
                }, (error) => {
                    window.console.log(error.response.status)
                    if (error.response.status === 401) {
                        commit('auth/SIGN_OUT')
                        router.push({ path: '/SignIn' })
                    }
                    if (error.response.status === 500) {
                        commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to connect to server'])
                    }
                })
        },
        SEARCH_RECENTLY_FINALIZED: async function({ commit, getters }) {
            await axios.post(`${apiPath1}WorkDashboard/GetRecentlyArchived`, { ...getters.archiveParams, 'TAKE': 50 }, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                // Hard code the limit of 50 to prevent the CustomizedRequestTable
                // from going past 50 request.
                commit('SET_ALL_ARCHIVE_REQUESTS_COUNT', 50 )
                commit('SET_ALL_ARCHIVE_REQUESTS', { requests: response.data.requests })
                commit('SET_WORKLIST_NOTIFICATIONS', response.data.notificationFlags)
            }, (err) => {
                window.console.log(error.response.status)
                if (error.response.status === 401) {
                    commit('auth/SIGN_OUT')
                    router.push({ path: '/SignIn' })
                }
                if (error.response.status === 500) {
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to connect to server'])
                }
            })
        },
        UPDATE_SINGLE_REQUEST: async function({ state, commit }, updatedRequest) { 
            //updatedRequest is the non-paginated model
            //need to convert it to the new paginated model
            let matchingStateRequest = state.requests.filter ( x => x.paRequestId == updatedRequest.model.paRequestId)[0];
            let index = state.requests.findIndex(r => r.paRequestId === updatedRequest.paRequestId)

            let primary = updatedRequest.model.primarySubmissionInfo.submissionMethod;
            let secondary = updatedRequest.model.secondarySubmissionInfo.submissionMethod;
            let tertiary = updatedRequest.model.tertiarySubmissionInfo.submissionMethod;
            
            /**
             * If matching requests have been found then use their submission info. if a
             * matching state request was not found, use the submission info from the
             * updated request model
             */
            if (matchingStateRequest) {
                updatedRequest.model.submissionInfos = [...matchingStateRequest.submissionInfos]

                updatedRequest.model.primaryCptCode = matchingStateRequest.primaryCptCode;
                updatedRequest.model.primaryCptDescription = matchingStateRequest.primaryCptDescription;
                updatedRequest.model.secondaryCptCode = matchingStateRequest.secondaryCptCode;
                updatedRequest.model.secondaryCptDescription = matchingStateRequest.secondaryCptDescription;
                updatedRequest.model.tertiaryCptCode = matchingStateRequest.tertiaryCptCode;
                updatedRequest.model.tertiaryCptDescription = matchingStateRequest.tertiaryCptDescription;
                updatedRequest.model.lastUserComment = matchingStateRequest.lastUserComment;
            } else {
                updatedRequest.model.submissionInfos = [
                    {
                        ...updatedRequest.model.primarySubmissionInfo, 
                        uiSubmissionMethod: primary, 
                        uiSubmissionCategoryName: updatedRequest.model.primarySubmissionInfo.submissionStatus,
                        uiSubmissionCategoryId: updatedRequest.model.primarySubmissionInfo.submissionPortalCheckStatusCategoryId
                    }
                ];
    
                if (secondary && secondary != "") {
                    updatedRequest.model.submissionInfos.push({
                        ...updatedRequest.model.secondarySubmissionInfo,
                        uiSubmissionMethod: secondary, 
                        uiSubmissionCategoryName: updatedRequest.model.secondarySubmissionInfo.submissionStatus,
                        uiSubmissionCategoryId: updatedRequest.model.secondarySubmissionInfo.submissionPortalCheckStatusCategoryId
                    });
                }
    
                if (tertiary && tertiary != "") {
                    updatedRequest.model.submissionInfos.push({
                        ...updatedRequest.model.tertiarySubmissionInfo,
                        uiSubmissionMethod: tertiary, 
                        uiSubmissionCategoryName: updatedRequest.model.tertiarySubmissionInfo.submissionStatus,
                        uiSubmissionCategoryId: updatedRequest.model.tertiarySubmissionInfo.submissionPortalCheckStatusCategoryId
                    }) ;
                }
            }

            updatedRequest.model.primaryInsuranceCompanyName = updatedRequest.model.primaryInsurance;
            updatedRequest.model.facilityName = updatedRequest.model.facilityID

            
            if (index > -1) {
                commit('SET_UPDATED_REQUEST_IN_ALL_REQUESTS', { index: index, request: updatedRequest.model })
                commit('SET_SOCKET_PA_UPDATE_ID', updatedRequest.paRequestId)
            }
        },
        UPDATE_PA_SUBMISSION: function({ state, commit }, updatedSubmission) {
            if (state.paRequestAndFormData && state.paRequestAndFormData.paRequest.paRequestId === updatedSubmission.paRequestId) {
                commit('SET_SUBMISSION_STATUS', updatedSubmission.submissionsForPaRequest);
            }

            const uniqueSubHistory = {
                submissionId: updatedSubmission.submissionId,
                submissionHistory: updatedSubmission.historyForSubmission.reverse()
            };

            commit('SET_PA_SUBMISSION_HISTORY', uniqueSubHistory);
        },
        CLEAR_SUBMISSION_HISTORY: function ({ commit }) {
            // we clear submission history when a user navigates back to the worklist
            // without this, the submission history array would keep being added to with
            // each submission viewed, which would start to hog client resources
            commit('SET_PA_SUBMISSION_HISTORY', false);
        },
        GET_PA_SUBMISSION_HISTORY: async function({state, commit, dispatch}, submissionId) {
            await axios.get(`${apiPath1}Submissions/GetSubmissionHistory/${submissionId}`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                  Pragma: "no-cache",
                },
              }).then((response) => {
                const uniqueSubHistory = {
                    submissionId: submissionId,
                    submissionHistory: response.data.reverse()
                };
                commit('SET_PA_SUBMISSION_HISTORY', uniqueSubHistory);
              }).catch((err) => {
                if (err.response?.status === 401) {
                    this.$store.dispatch("auth/SIGN_OUT");
                }
                window.console.log(err);
              });
        },
        GET_ALL_DEPARTMENTS: async function({ state, commit, dispatch }) {
            await axios.get(`${apiPath1}WorkDashboard/GetDepartments`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {})
                .catch(error => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(error)
                })

        },
        GET_DEPARTMENT_FOLLOW_UP_DATE_RULES: async function({commit, dispatch}) {
            await axios.get(`${apiPath1}WorkerDashboardManager/GetDepartmentFollowUpDateRules`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_DEPARTMENT_FOLLOW_UP_DATE_RULES', { departmentFollowUpDateRules: response.data });
            })
            .catch(err => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT');
            })
        },
        GET_REQUEST_AND_FORM_DATA: async function({ commit, dispatch }, paRequestId) {
            await axios.get(`${apiPath1}WorkDashboard/GetPaRequestWithMedicalFormData/${paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_AND_FORM_DATA', { paRequestAndFormData: response.data })
                })
                .catch(err => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                })
        },
        POST_PA_REQUEST_OVERVIEW: async function({ commit, dispatch, getters, state }, params) {
            await axios.post(`${apiPath1}WorkDashboard/GetPaRequestOverview/`, 
                {
                    paRequestId: params.paRequestId,
                    isArchive: params.isArchive,
                    sortAndFilters: { ...(params.isArchive ? getters.archiveParams : getters.worklistParams) }
                }, 
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                dispatch('GET_SUBMISSION_STATUS', params.paRequestId);
                dispatch('GET_EVENT_FEED', params.paRequestId);

                let eligibility = {}
                if (response.data.eligibilityPrimary) eligibility = {...eligibility, Primary: response.data.eligibilityPrimary};
                if (response.data.eligibilitySecondary) eligibility = {...eligibility, Secondary: response.data.eligibilitySecondary};
                if (response.data.eligibilityTertiary) eligibility = {...eligibility, Tertiary: response.data.eligibilityTertiary};

                // keeps the next and previous request link up to date
                commit('SET_PA_REQUEST_ID_ARRAY', [
                    response.data.prevPaRequest,
                    parseInt(params.paRequestId),
                    response.data.nextPaRequest
                ]);
                commit('SET_REQUEST_ELIGIBILITY', eligibility);
                commit('SET_PA_REQUEST_OVERVIEW', response.data);
                commit('SET_REQUEST_AND_FORM_DATA', { paRequestAndFormData: response.data.paRequestWithMedicalFormData });
                commit('SET_REQUEST_COMPLETION', { requestCompletion: response.data.completion });
                commit('SET_REQUEST_COMMENTS', response.data.comments);
                commit('SET_PA_LAST_VIEW_DATE', response.data.lastViewedDate);
                commit('SET_PA_EMR_DIFFERENCES', response.data.emrDifferences);
                commit('SET_PA_HAS_RELATED_PAS', response.data.hasRelatedPas);
                commit('medicalNecessity/SET_REQUIREMENTS_INFO', response.data.requirementsInfo);
                commit('medicalNecessity/SET_DISPLAY_TAB', response.data.enableMedicalNecessities);
            })
            .catch(err => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT');
            });
        },
        GET_PA_APPOINTMENT_STATUS: async function({ commit, dispatch, getters, state }, params) {
            try {
                const response = await axios.get(`${apiPath1}VerataOrders/GetAppointmentStatus/${params.paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                if(response && response.data && response.data !== null && response.data !== '' && response.data !== 'Unknown'){
                    commit('SET_PA_APPOINTMENT_STATUS', response.data)
                } else {
                    commit('SET_PA_APPOINTMENT_STATUS', null)
                }
            } catch (err) {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                else {
                    console.warn('Error retrieving PA Appointment status')
                    commit('SET_PA_APPOINTMENT_STATUS', null)
                }
            }
        },
        GET_SHARED_PA_INFO: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}WorkDashboard/GetSharedPaRequestInfo`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_SHARED_PA_INFO', response.data)
                    commit('SET_WORKLIST_DISPLAY_SETTINGS', response.data.worklistDisplaySettings)
                    commit('SET_FACILITIES', response.data.facilities.filter(f => (f.name != null && f.name != "")));
                    dispatch('UPDATE_CURR_USER', sessionStorage.getItem('currUserId'))
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                });
        },
        GET_HOSPITAL_NETWORK: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}WorkDashboard/GetHospitalNetworks`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then(response => {
                    if (response.data.length > 0) {
                        commit('SET_HOSPITAL_NETWORK', response.data)
                        return
                    }
                    commit('SET_HOSPITAL_NETWORK', false)
                })
                .catch(err => {
                    commit('SET_HOSPITAL_NETWORK', false)
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    console.log(err)
                })
        },
        GET_REQUEST_FORM_DATA: function({ commit, dispatch }, paRequestId) {
            axios.get(apiPath1 + `/WorkDashboard/GetMedicalFormData/${paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_FORM_DATA', { formData: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_REQUEST_AND_FORM_DATA: async function({ commit, dispatch }, params) {
            // convert ""null" string to null value
            if (params.data.paRequest.assignedToId === "null") params.data.paRequest.assignedToId = null;
            await axios.put(apiPath1 + `WorkDashboard/UpdatePaRequestWithMedicalFormData/${params.paRequestId}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                  let eligibility = {}
                  if (response.data.eligibilityPrimary) eligibility = {...eligibility, Primary: response.data.eligibilityPrimary}
                  if (response.data.eligibilitySecondary) eligibility = {...eligibility, Secondary: response.data.eligibilitySecondary} 
                  if (response.data.eligibilityTertiary) eligibility = {...eligibility, Tertiary: response.data.eligibilityTertiary}

                    commit('SET_REQUEST_ELIGIBILITY', eligibility)
                    commit('SET_REQUEST_AND_FORM_DATA', { paRequestAndFormData: response.data.paRequestWithMedicalFormData })
                    commit('SET_REQUEST_COMPLETION', { requestCompletion: response.data.completion })
                    commit('SET_REQUEST_COMMENTS', response.data.comments)
                    commit('SET_PA_LAST_VIEW_DATE', response.data.lastViewedDate)
                    commit('SET_PA_HAS_RELATED_PAS', response.data.hasRelatedPas)
                    commit('SET_PA_EMR_DIFFERENCES', response.data.emrDifferences)

                    // get the latest activity feed updates
                    dispatch('GET_EVENT_FEED', params.paRequestId)

                    commit('SET_GLOBAL_MESSAGE', ['status', `PA Request ${params.paRequestId} updated`])
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', `PA Request update failed`])
                    if (err.response.data === 'You cannot assign to Olive ') {
                        commit('SET_GLOBAL_MESSAGE', ['error', `Requests assigned to Olive cannot be updated unless re-assigned.`])
                    }
                    window.console.log(err.response)
                })
        },
        UPDATE_REQUEST_FORM_DATA: async function({ commit, dispatch }, params) {
            await axios.put(apiPath1 + `/WorkDashboard/UpdateMedicalFormData/${params.paRequestId}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_EVENT_FEED', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_REQUEST: async function({ state, commit, dispatch }, params) {
            // convert ""null" string to null value
            if (params.data.paPersonId === "null") params.data.paPersonId = null;

            await axios.put(apiPath1 + `WorkDashboard/UpdatePaReq/${params.paRequestId}?idWho=${params.idWho}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('auth/REFRESH_AUTH_TOKEN')
                    dispatch('GET_WORKERS')
                    if (state.fallbackToPolling) { dispatch('GET_PAGINATED_REQUESTS'); }
                    dispatch('GET_EVENT_FEED', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_ASSIGNED_TO_ID: async function({ state, commit, dispatch }, params) {
            let endpoint = `${params.paRequestId}`
            if(params.assignedToId) endpoint += `/${params.assignedToId}`
            await axios.put(apiPath1 + `WorkDashboard/UpdateAssignedTo/${endpoint}`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('auth/REFRESH_AUTH_TOKEN')
                    dispatch('GET_WORKERS')
                    if (state.fallbackToPolling) {
                        dispatch('GET_PAGINATED_REQUESTS');
                        dispatch('GET_ASSIGNEDTO_REQUEST_COUNT');
                    }
                    dispatch('GET_EVENT_FEED', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_REQUEST_STATUS: async function({ state, commit, dispatch }, params) {
            await axios.put(apiPath1 + `WorkDashboard/UpdateStatus/${params.paRequestId}/${params.statusId}`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('auth/REFRESH_AUTH_TOKEN')
                    dispatch('GET_WORKERS')
                    if (state.fallbackToPolling) { dispatch('GET_PAGINATED_REQUESTS'); }
                    dispatch('GET_EVENT_FEED', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_FOLLOWUP_DATE: async function({ state, commit, dispatch }, params) {
          let endpoint = `${params.paRequestId}`
          if(params.followUpDate) endpoint += `/${params.followUpDate}`
            await axios.put(apiPath1 + `WorkDashboard/UpdateFollowUpDate/${endpoint}`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('auth/REFRESH_AUTH_TOKEN')
                    dispatch('GET_WORKERS')
                    if (state.fallbackToPolling) { dispatch('GET_PAGINATED_REQUESTS'); }
                    dispatch('GET_EVENT_FEED', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_REQUEST_DIAGNOSIS: function({ commit, dispatch }, params) {
            axios.put(apiPath1 + `WorkDashboard/UpdatePaReqDiagnosis/${params.paRequestId}?idWho=${params.idWho}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_WORKERS')
                    dispatch('GET_PAGINATED_REQUESTS')
                    commit('SET_GLOBAL_MESSAGE', ['status', `Diagnosis for PA Request ${params.mrn} updated`])
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    commit('SET_GLOBAL_MESSAGE', ['error', `Unable to set Diagnosis for PA Request ${params.mrn}`])
                })
        },
        GET_REQUEST_COMPLETION: function({ commit, dispatch }, requestId) {
            axios.get(`${apiPath1}WorkDashboard/GetCompletionForPaRequest/${requestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_COMPLETION', { requestCompletion: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_DOCUMENT_URLS: async function({ commit, dispatch }, docId) {
            await axios.post(`${apiPath1}BlobDocuments/GetUrls/${docId}`, docId, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_DOCUMENT_URLS', { documentUrls: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_DOCUMENT_PREVIEWS: async function({ commit, dispatch }, id) {
            await axios.post(`${apiPath1}BlobDocuments/GetPreviewUrls/${id}`, id, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_DOCUMENT_PREVIEWS', { documentPreviews: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_DOCUMENT_LISTS: async function({ commit, dispatch }, requestId) {
            await axios.get(`${apiPath1}AttachedDocuments/GetAttachedAndAvailableDocumentationForPaRequest/${requestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_ATTACHED_FILES', { attachedFiles: response.data.attached })
                    commit('SET_SUGGESTED_FILES', { suggestedFiles: response.data.available })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        BULK_ATTACH_DOCUMENTS: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}AttachedDocuments/BulkSetAttachedDocuments/`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    commit('SET_ATTACHED_FILES', { attachedFiles: response.data.attached })
                    commit('SET_SUGGESTED_FILES', { suggestedFiles: response.data.available })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        DETACH_DOCUMENT_FROM_PA_BUNDLE: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}AttachedDocuments/DetachNote`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    commit('SET_ATTACHED_FILES', { attachedFiles: response.data.attached })
                    commit('SET_SUGGESTED_FILES', { suggestedFiles: response.data.available })
                    dispatch('GET_REQUEST_COMPLETION', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        ATTACH_DOCUMENT_TO_PA_BUNDLE: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}AttachedDocuments/AttachDocument`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    commit('SET_ATTACHED_FILES', { attachedFiles: response.data.attached })
                    commit('SET_SUGGESTED_FILES', { suggestedFiles: response.data.available })
                    dispatch('GET_REQUEST_COMPLETION', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        CLEAR_DOCUMENT_MODAL: function({ commit }) {
            commit('SET_DOCUMENT_URLS', { documentUrls: false })
            commit('SET_DOCUMENT_PREVIEWS', { documentPreviews: false })
        },
        GET_ATTACHED_FILES: function({ commit, dispatch }, requestId) {
            axios.get(`${apiPath1}AttachedFiles/GetFilesForPaRequest/${requestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_ATTACHED_FILES', { attachedFiles: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_SUGGESTED_FILES: function({ commit, dispatch }, requestId) {
            axios.get(`${apiPath1}AttachedFiles/ListNotes/${requestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_SUGGESTED_FILES', { suggestedFiles: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_PA_REQUEST_FORM: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}AttachedFiles/GetForm?paRequestId=${params.requestId}&insuranceCompanyId=${params.insuranceCompanyId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_PA_REQUEST_FORM', { paRequestForm: response.data })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPLOAD_FILE_TO_PA: async function({ commit, dispatch }, data) {
            await axios.post(`${apiPath1}AttachedFiles/PostAttachedFile`, data, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Conent-Type': 'multipart/form-data',
                    },
                    onUploadProgress: function(progressEvent) {
                        let uploadPercentage = parseInt(Math.round((progressEvent.loaded * 99) / progressEvent.total));
                        commit('SET_UPLOAD_PROGRESS', uploadPercentage)
                    }
                })
                .then(function(res) {
                    commit('SET_GLOBAL_MESSAGE', ['status', `Document uploaded successfully`])
                    if (res.data.attached) commit('SET_ATTACHED_FILES', { attachedFiles: res.data.attached })
                    if (res.data.available) commit('SET_SUGGESTED_FILES', { suggestedFiles: res.data.available })
                    commit('SET_UPLOAD_PROGRESS', 0)
                })
                .catch(function(err) {
                    if (err.response && err.response.status === 401) dispatch('auth/SIGN_OUT')
                    let displayError = err
                    if(err.repsonse) displayError = err.response
                    if(err.response && err.response.data) displayError = err.response.data
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Upload Error: ' + displayError])
                    commit('SET_UPLOAD_PROGRESS', 0)
                    window.console.log('FAILURE!!', err.response);
                });
        },
        UPLOAD_ATTACHMENTS_TO_JIRA: async function ({ commit, dispatch }, data) {
            await axios.post(`${apiPath1}AttachedFiles/PostAttachedFile`, data, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Conent-Type': 'multipart/form-data',
                },
                onUploadProgress: function(progressEvent) {
                    let uploadPercentage = parseInt(Math.round((progressEvent.loaded * 99) / progressEvent.total));
                    commit('SET_UPLOAD_PROGRESS', uploadPercentage)
                }
            })
            .then(function(res) {
                commit('SET_GLOBAL_MESSAGE', ['status', `Attachment uploaded successfully`])
                commit('SET_UPLOAD_PROGRESS', 0)
            })
            .catch(function(err) {
                if (err.response && err.response.status === 401) dispatch('auth/SIGN_OUT')
                let displayError = err
                if(err.repsonse) displayError = err.response
                if(err.response && err.response.data) displayError = err.response.data
                commit('SET_GLOBAL_MESSAGE', ['error', 'Upload Error: ' + displayError])
                commit('SET_UPLOAD_PROGRESS', 0)
                window.console.log('FAILURE!!', err.response);
            });
        },
        DETACH_FILE_FROM_PA_BUNDLE: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}AttachedFiles/DetachNote`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_SUGGESTED_FILES', params.paRequestId)
                    dispatch('GET_ATTACHED_FILES', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        ATTACH_FILE_TO_PA_BUNDLE: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}AttachedFiles/AttachNote`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_SUGGESTED_FILES', params.paRequestId)
                    dispatch('GET_ATTACHED_FILES', params.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        DOWNLOAD_PA_FORM: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}AttachedFiles/Download/?blobName=${params.path}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    },
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    var newBlob = new Blob([response.data], { type: "application/pdf" })

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, params.name);
                        return;
                    }

                    const data = window.URL.createObjectURL(newBlob);

                    var link = document.createElement('a');
                    link.href = data;
                    link.download = `${params.name}`;
                    link.click();

                    setTimeout(function() {
                        window.URL.revokeObjectURL(data);
                    }, 100)
                })
                .catch(function(err) {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to download PA packet'])
                    window.console.log('FAILURE!!', err);
                });
        },
        DOWNLOAD_ATTACHED_FILE: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}AttachedDocuments/Download/${params.blobDocumentId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    },
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    var newBlob = new Blob([response.data], { "content-type": response.headers["content-type"] })
                    var extension = ""
                    var nameHasExtension = params.name.slice((params.name.lastIndexOf(".") - 1 >>> 0) + 2)
                    if (!nameHasExtension || (nameHasExtension != 'pdf' && nameHasExtension != 'jpg' && nameHasExtension != 'png' && nameHasExtension != 'txt')) {
                        extension = '.' + mime.extension(response.headers["content-type"])
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, `${params.name}${extension}`);
                        return;
                    }

                    const data = window.URL.createObjectURL(newBlob);

                    var link = document.createElement('a');
                    link.href = data;
                    link.download = `${params.name}${extension}`;
                    link.click();

                    setTimeout(function() {
                        window.URL.revokeObjectURL(data);
                    }, 100)
                })
                .catch(function(err) {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', `Unable to download ${params.name}`])
                    window.console.log('FAILURE!!', err);
                });
        },
        GET_PDF_STATUS: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}PaRequests/MostRecentPrintToPdfStatus/${params.paRequestId}/?insuranceCompanyId=${params.insuranceCompanyId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_PRINT_PDF_STATUS', response.data)
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_PRINT_PDF_STATUS', {})
                })
        },
        SET_PRINT_PDF_STATUS: function({ commit }, data) {
            commit('SET_PRINT_PDF_STATUS', data)
        },
        DOWNLOAD_PDF: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}PaRequests/GetPdfFile/${params.paRequestId}/${params.insuranceCompanyId}/${params.submissionId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    },
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    var newBlob = new Blob([response.data], { type: "application/pdf" })

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, `${params.fileName}.pdf`);
                        return;
                    }

                    const data = window.URL.createObjectURL(newBlob);

                    var link = document.createElement('a');
                    link.href = data;
                    link.download = `${params.fileName}.pdf`;
                    link.click();

                    setTimeout(function() {
                        window.URL.revokeObjectURL(data);
                    }, 100)
                })
                .catch(function(err) {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', 'Unable to download PA packet'])
                    window.console.log('FAILURE!!', err);
                });

        },
        SEND_FAX_REQUEST: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}PaRequests/FaxPaRequest`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {}, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', err.response.data])
                    return Promise.reject(new Error('sendFax'))
                })
        },
        SEND_SUBMISSION_REQUEST: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}Submissions/UpdateSubmissionsForPaRequest`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err);
                    // Handle the edge case where the use links their account
                    // Without refreshing the page.
                    if (err.response.data !== "User does not have a linked account")
                        commit('SET_GLOBAL_MESSAGE', ['error', `${err.response.data}`])
                    else
                        commit('attestation/SET_NOT_LINKED_ACCOUNT', true)
                })
        },
        GET_SUBMISSION_STATUS: async function({ commit, dispatch }, id) {
            await axios.get(`${apiPath1}Submissions/GetSubmissionsForPaRequest/${id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_SUBMISSION_STATUS', response.data)
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_AAR_DIRECT_SUBMISSION_STATUS: async function({ commit, dispatch }, id) {
            await axios.post(`${apiPath1}Submissions/SubmitAARSubmission/${id}`, {}, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_SUBMISSION_STATUS', response.data)
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_SUBMISSION_METHODS: async function({ commit }) {
          await axios.get(`${apiPath1}Submissions/GetSubmissionMethods/`, {
                  headers: {
                      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                      'Pragma': 'no-cache'
                  }
              })
              .then((response) => {
                  commit('SET_SUBMISSION_METHODS', response.data)
              })
              .catch((err) => {
                  if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                  window.console.log(err)
              })
        },
        GET_ASSIGNEDTO_REQUEST_COUNT: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}WorkDashboard/GetAssignedToCounts`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_ASSIGNEDTO_REQUEST_COUNT', response.data)
            })
            .catch((err) => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                window.console.log(err)
            })
        },
        TOGGLE_MODAL: function({ commit }, params) {
            commit('SET_MODAL_DISPLAY', { params })
        },
        GET_MODAL_DATA: ({ commit, dispatch }, params) => {
            // TODO
            // Fetch comments based on modal type
            axios.get(apiPath1 + `WorkDashboard/GetCommentsForPaRequest/${params.request.paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    if (params.modalType === 'showPayerModal') response.data = []
                    commit('SET_MODAL_DATA', { comments: response.data, request: params.request, modalType: params.modalType })
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_MODAL_PA_COMMENTS: ({ commit, dispatch }, paRequestId) => {
            axios.get(apiPath1 + `WorkDashboard/GetCommentsForPaRequest/${paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_MODAL_COMMENTS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        GET_PAYER_NOTES: ({ commit, dispatch }, payerId) => {
            axios.get(`${apiPath1}InsuranceCompanyComments/GetInsuranceCompanyCommentsForCompany/${payerId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_PAYER_NOTES', response.data)
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_PAYER_NOTES', [])
                    window.console.log(err)
                })
        },
        ADD_PAYER_NOTE: async({ commit, dispatch }, params) => {
            await axios.post(`${apiPath1}InsuranceCompanyComments/PostInsuranceCompanyComment`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_PAYER_NOTES', params.data.insuranceCompanyId)
                })
                .catch((err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        UPDATE_PAYER_NOTE: ({ dispatch }, params) => {
            axios.put(`${apiPath1}InsuranceCompanyComments/PutInsuranceCompanyComment/${params.noteId}`, params.body, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_PAYER_NOTES', params.body.insuranceCompanyId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_EVENT_FEED: async function({ commit, dispatch }, requestId) {
            commit('SET_PA_EVENT_FEED_LOADING', true)
            axios.get(`${apiPath1}PaRequests/GetPaRequestEvents/${requestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_PA_EVENT_FEED', { eventFeed: response.data })
                    commit('SET_PA_EVENT_FEED_LOADING', false)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    commit('SET_PA_EVENT_FEED_LOADING', false)
                })
        },
        GET_REQUEST_COMMENTS: async function({ commit, dispatch }, paRequestId) {
            await axios.get(apiPath1 + `WorkDashboard/GetCommentsForPaRequest/${paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_COMMENTS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        ADD_NOTE: ({ dispatch }, params) => {
            axios.post(`${apiPath1}PaRequestComments/PostPaRequestComment`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    dispatch('GET_REQUEST_COMMENTS', params.data.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })

        },
        UPDATE_NOTE: ({ dispatch }, params) => {
            axios.put(apiPath1 + `PaRequestComments/PutPaRequestComment/${params.commentId}`, params.body, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })  
                .then((response) => {
                    dispatch('GET_REQUEST_COMMENTS', params.body.paRequestId)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                })
        },
        GET_REPORT_GROUPS: async function({ commit, dispatch }) {
            await axios.get(apiPath1 + `Report/GetReportsByGroup`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REPORT_GROUPS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        GET_REPORT_LIST: async function({ commit, dispatch }) {
            await axios.get(apiPath1 + `Report/Index`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    let reportList = response.data;
                    //add full report name
                    for (let i = 0; i < reportList.length; i++){
                        reportList[i].fullReportName = reportList[i].groupName + ' - ' + reportList[i].name;
                    }
                    commit('SET_REPORT_LIST', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        GET_REPORT: async function({ commit, dispatch }, reportId) {
            if (reportId) {
                await axios.get(apiPath1 + `Report/GetMostRecentReportResult/${reportId}`, {
                        headers: {
                            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                            'Pragma': 'no-cache'
                        }
                    })
                    .then((response) => {
                        commit('SET_REPORT', response.data)
                    }, (err) => {
                        if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                        window.console.log(err)
                        return err;
                    })
            } else {
                commit('SET_REPORT', '')
            }
        },
        RUN_REPORT: async function({ commit, dispatch }, params) {
            await axios.post(apiPath1 + `Report/RunReport/${params.reportId}`, params.dateRange, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    }
                })
                .then((response) => {
                    commit('SET_REPORT', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        LOG_ERROR: async function({}, params) {
            await axios.put(apiPath1 + `WorkDashboardLogging/LogMessage`, params)
                .then((response) => {
                }, (err) => {
                    return err;
                })
        },
        RESET_WORKLIST_SORT: ({ commit, dispatch, state }) => {
            const initialSort = state.worklistSortInfo[0] 

            commit(
                'SET_WORKLIST_SORT',
                [
                    new Sort({
                        field: initialSort?.field ?? 'urgency',
                        direction: initialSort?.direction ?? -1
                    })
                ]
            )

           dispatch("worklist/RESET_ACTIVE_COLUMN_HEADERS")
        },
        SET_WORKLIST_SORT: ({ commit }, params) => {
            commit('SET_WORKLIST_SORT', params)
        },
        REMOVE_WORKLIST_SORT: ({ commit, state }, params) => {
            commit(
                'SET_WORKLIST_SORT', 
                state.worklistSortInfo.filter(
                    worklistSort => worklistSort.field !== params.field
                )
            )
        },
        UPDATE_CURR_USER: ({ commit }, userId) => {
            commit('SET_CURRENT_USER', userId)
        },
        CLEAR_PA_REQUEST_STATE: ({ commit }) => {
            commit('SET_PA_EVENT_FEED', { eventFeed: [] })
            commit('SET_REQUEST_COMMENTS', [])
            commit('SET_ATTACHED_FILES', { attachedFiles: false })
            commit('SET_SUGGESTED_FILES', { suggestedFiles: false })
            commit('SET_PA_REQUEST_FORM', { paRequestForm: false })
            commit('SET_PAYER_NOTES', false)
        },
        SET_GLOBAL_MESSAGE: ({ commit }, message) => {
            commit('SET_GLOBAL_MESSAGE', message)
        },
        CLEAR_GLOBAL_MESSAGE: ({ commit }) => {
            commit('SET_GLOBAL_MESSAGE', ['error', '', 'timer'])
        },
        SET_NOTIFICATION_FLAGS: async function({ commit, dispatch }, params) {
            await axios.put(apiPath1 + `WorkDashboard/SetPaRequestNotificationFlags/${params.paRequestId}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                }, (err) => {
                    window.console.log(err)
                })
        },
        TOGGLE_PORTAL_ASSISTANT: ({ commit }, params) => {
            commit('SET_PORTAL_ASSISTANT_DISPLAY', params);
        },
        TOGGLE_DIRECT_SUBMIT_WINDOW: ({ commit }, params ) => {
            commit('SET_DIRECT_SUBMIT_WINDOW_DISPLAY', params);
        },
        GET_FILTER_COUNTS: async ({ commit }) => {
            let startTime = new Date().getTime()

            commit('worklist/SET_DEFAULT_FILTER_TO_SEARCH_ON_START', null)

            await axios.get(`${apiPath1}WorkDashboard/GetFilterCounts`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                let endTime = new Date().getTime()

                const systemFilters = response.data.filters.filter( data => data.isSystemFilter )
                const customUserFilters = response.data.filters.filter( data => !data.isSystemFilter )

                const defaultFilterId = response.data.defaultFilterId
                if (defaultFilterId) {
                    const defaultFilter = customUserFilters.find(
                        customUserFilter => customUserFilter.worklistFilterId === defaultFilterId
                    )
                    
                    commit('worklist/SET_DEFAULT_FILTER_TO_SEARCH_ON_START', defaultFilter)

                    commit('worklist/SET_DEFAULT_FILTER', 
                        defaultFilter ? 
                            defaultFilter.name.replace(/\(\d+\)/, '').trim() : 
                            'all_requests'
                    )
                } else {
                    commit('worklist/SET_DEFAULT_FILTER', 'all_requests')
                }


                commit('SET_SYSTEM_FILTERS', systemFilters)
                commit('SET_CUSTOM_USER_FILTERS', customUserFilters)

                logger.logTimeForGetFilterCounts(endTime - startTime, systemFilters, customUserFilters)
            })
        },
        UPDATE_CUSTOM_USER_FILTERS: ({ commit }, customUserFilters) => {
            commit("UPDATE_CUSTOM_USER_FILTERS", customUserFilters)
        },
        CLEAR_LAST_VIEWED_REQUEST_FOR_USER: async ({ dispatch, state }) => {
            const paRequestId = state.paRequestAndFormData?.paRequest?.paRequestId
            const clearPaRequestId = state.paRequestAndFormData?.lastViewedByData?.clearPaRequestId

            if (paRequestId && clearPaRequestId) {
                await axios.post(`${apiPath1}WorkDashboard/ClearPaRequestViewFlag/${paRequestId}/${clearPaRequestId}`, {}, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache',
                    }
                }).then( 
                    (response) => {},
                    (err) => {
                        if (err.response.status === 401) {
                            dispatch('auth/SIGN_OUT')
                        }
                        
                        window.console.log(err)
                        return err
                    }
                )
            }

            return Promise.resolve()
        },
        CLEAR_ROOT_STATE: ({ state, commit, dispatch }) => {
            const s = initialState();
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });

            sessionStorage.setItem('worklistCurrentPage', 0)

            dispatch("CLEAR_LAST_VIEWED_REQUEST_FOR_USER");
            commit("faxSubmission/RESET");
            commit("reporting/RESET", true);
            dispatch("worklist/RESET")
            dispatch("archive/RESET")
            dispatch("downloadQueue/RESET")

            // close the websocket connection
            dispatch("socketConnection/RESET");

            router.push("/Signin");

            // reset customer logo after routing to Sign In to avoid an unsaved state change that blocks it
            commit("customerLogos/RESET");
        },
        SET_SEARCH_TERM: ({ commit }, searchTermInstance) => {
            commit('SET_SEARCH_TERM', searchTermInstance)
        },
        SET_ARCHIVE_SORT: ({ commit }, archiveSortInfo) => {
            commit('SET_ARCHIVE_SORT', archiveSortInfo)
        },
        REMOVE_ARCHIVE_SORT: ({ commit, state }, params) => {
            commit(
                'SET_ARCHIVE_SORT', 
                state.archiveSortInfo.filter(
                    archiveSort => archiveSort.field !== params.field
                )
            )
        },
        RESET_ARCHIVE_SORT: ({ commit, dispatch, state }) => {
            // clear out the archived requests
            const initialSort = state.archiveSortInfo[0] 

            commit(
                'SET_ARCHIVE_SORT',
                [
                    new Sort({
                        field: initialSort?.field ?? 'urgency',
                        direction: initialSort?.direction ?? -1
                    })
                ]
            )

           dispatch("archive/RESET_ACTIVE_COLUMN_HEADERS")
        },

    },
    mutations: {
        SET_SEARCH_TERM: (state, textSearchTerm) => {
            state.textSearchTerm = textSearchTerm
            sessionStorage.setItem('textSearchTerm', JSON.stringify(textSearchTerm))
        },
        SET_SYSTEM_FILTERS: (state, systemFilters) => {
            state.systemFilters = systemFilters.map((systemFilter, index) => {
                return {
                    ...systemFilter,
                    ...(PaginatedFilterMapping.reverseEngineerFilters({ filter: JSON.stringify({
                        Filter: { 
                            Filters: systemFilter.filter.filter.filters.map(filter => {
                                return new Filter({
                                    field: filter.field,
                                    operator: filter.operator,
                                    value: `${filter.value}`,
                                })
                            })
                        },
                        Logic: systemFilter.filter.logic,
                        Sort: systemFilter.filter.sort,
                        Take: systemFilter.filter.take
                    })})),
                    worklistFilterId: `s${index + 2}`,
                    name: systemFilter.name
                }
            })

            state.systemFilters.unshift({
                worklistFilterId: `s1`,
                filter: "{}",
                isSystemFilter: true,
                name: 'All Requests'
            })
        },
        UPDATE_CUSTOM_USER_FILTERS: (state, customFilters) => {
            state.customUserFilters = customFilters.map(
                    customFilter => PaginatedFilterMapping.reverseEngineerFilters(customFilter)
                )
        },
        SET_CUSTOM_USER_FILTERS: (state, customUserFilters) => {
            if (!customUserFilters) {
                state.customUserFilters = []
                return
            }

            state.customUserFilters = (customUserFilters || [])?.map(
                customUserFilter => {
                    const currentFilter = customUserFilter?.filter && 
                                        customUserFilter?.filter?.filter && 
                                        customUserFilter?.filter?.filter?.filters ?
                                            customUserFilter?.filter?.filter?.filters :
                                            []

                    const hasCurrentFilterCountInString = customUserFilter?.name?.match(/\d+/)

                    if (currentFilter?.length) {
                        return {
                            ...(PaginatedFilterMapping.reverseEngineerFilters({
                                ...customUserFilter,
                                filter: JSON.stringify({
                                    Filter: { 
                                        Filters: (currentFilter || [])?.map(filter => {
                                            return new Filter({
                                                field: filter?.field,
                                                operator: filter?.operator,
                                                value: `${filter?.value}`,
                                            })
                                        }),
                                    },
                                    Logic: customUserFilter?.filter?.logic,
                                    Sort: customUserFilter?.filter?.sort,
                                    Take: customUserFilter?.filter?.take
                                })
                            })),
                            modifiers: customUserFilter?.filter?.filter?.modifiers ?? {},
                            name: customUserFilter?.name?.replace(/\(\d+\)/, '').trim(),
                            count: hasCurrentFilterCountInString ? hasCurrentFilterCountInString[0] : 0,
                            sort: customUserFilter?.filter?.sort
                        }
                    }
                }).filter(filter => filter)
        },
        SET_IS_IE: (state, bool) => {
          state.browserIsIE = bool
        },
        ADD_USER_NOTIFICATION: (state, notification) => {
            state.userNotifications.push({
                viewed: false,
                when: notification.when,
                data: notification
            })
        },
        SET_USER_NOTIFICATIONS: (state, notifications) => {
            state.userNotifications = notifications
        },
        SET_LOADING_RECENTLY_VIEWED_REQUESTS: (state, loadingRecentlyViewedRequests) => {
            state.loadingRecentlyViewedRequests = loadingRecentlyViewedRequests
        },
        SET_RECENTLY_VIEWED_REQUESTS: (state, requests) => {
            state.recentlyViewedRequests = requests
        },
        SET_RELEASE_NOTES_DISPLAY: (state, params) => {
          state.showReleaseNotes = params
        },
        SET_TOKEN_EXPIRES: (state, seconds) => {
            state.tokenExpires = seconds
        },
        SET_UPLOAD_PROGRESS: (state, progress) => {
            state.uploadProgress = progress
        },
        SET_AVAILABLE_ROLES: (state, { roles }) => {
            state.availableRoles = roles
        },
        SET_WORKERS: (state, { workers }) => {
            state.workers = workers
            state.currUser = workers.find(worker => worker.paPersonId === state.auth.currUserID)
        },
        SET_UPDATED_WORKER_IN_ALL_WORKERS: (state, { index, worker }) => {
            state.workers.splice(index, 1, worker)
        },
        SET_CURRENT_USER: (state, userId) => {
            state.currUser = state.workers.find(worker => worker.paPersonId === parseInt(userId))
        },
        SET_LINKED_ACCOUNTS: (state, accounts) => {
            state.linkedAccounts = accounts
        },
        SET_PORTAL_LOGINS: (state, logins) => {
            state.portalLogins = logins
        },
        SET_UPCOMING_RELEASE_NOTES: (state, upcoming) => {
            state.upcomingReleaseNotes = upcoming
        },
        SET_FETCHING_REQUESTS: (state) => {
            state.fetchingRequests = true
        },
        SET_ALL_REQUESTS: (state, { requests }) => {
            requests.forEach(request => {
              request.patientFullName = `${request.lastName}, ${request.firstName}`
              Object.freeze(request)
            })
            Vue.set(state, 'requests', requests)
            state.fetchingRequests = false
        },
        SET_ALL_ARCHIVE_REQUESTS: (state, { requests }) => {
            if (requests)
                requests.forEach(request => {
                    request.patientFullName = `${request.lastName}, ${request.firstName}`
                    Object.freeze(request)
                })
            Vue.set(state, 'requestsArchive', requests)
            state.fetchingRequests = false;
        },
        SET_ALL_REQUESTS_COUNT: (state, count) => {
            state.allRequestsCount = count
        },
        SET_ALL_ARCHIVE_REQUESTS_COUNT: (state, count) => {
            state.allArchiveRequestsCount = count
        },
        SET_PA_SEARCH_TERM: (state, term) => {
            state.paSearchTerm = term
        },
        SET_PA_REQUEST_ID_ARRAY: (state, filteredPaRequestIdArray) => {
            state.filteredPaRequestIdArray = filteredPaRequestIdArray
            sessionStorage.setItem('worklistPaRequestIdArray', filteredPaRequestIdArray)
        },
        SET_WORKLIST_CURRENT_FILTERS: (state, filters) => {
            state.worklistCurrentFilters = filters
        },
        SET_WORKLIST_NOTIFICATIONS: (state, flags) => {
            state.worklistNotifications = flags
        },
        SET_WORKLIST_DISPLAY_SETTINGS: (state, worklistDisplaySettings) => {
            state.worklistDisplaySettings = worklistDisplaySettings
        },
        SET_WORKLIST_CURRENT_PAGE: (state, page) => {
            state.worklistCurrentPage = page
        },
        SET_ARCHIVE_CURRENT_PAGE: (state, page) => {
            state.archiveCurrentPage = page
        },
        SET_SOCKET_PA_UPDATE_ID: (state, paRequestId) => {
            state.socketUpdateToPa = paRequestId
        },
        SET_UPDATED_REQUEST_IN_ALL_REQUESTS: (state, { index, request }) => {
            request.patientFullName = `${request.lastName}, ${request.firstName}`
            let updatedPa = Object.assign({}, Object.freeze(request))
            state.requests.splice(index, 1, updatedPa)
        },
        SET_CURRENT_TAB_CHANGES: (state, bool) => {
            state.currentTabChanges = bool
        },
        SET_UNSAVED_MODAL_DISPLAY: (state, bool) => {
            state.showUnsavedModal = bool
        },
        SET_REQUEST_AND_FORM_DATA: (state, { paRequestAndFormData }) => {
            state.paRequestAndFormData = paRequestAndFormData
        },
        SET_PA_HAS_RELATED_PAS: (state, hasRelatedPas ) => {
            state.hasRelatedPas = hasRelatedPas
        },
        SET_REQUEST_FORM_DATA: (state, { formData }) => {
            state.requestFormData = formData
        },
        SET_SHOW_ALL_DEPARTMENT_REQUESTS: (state, showAllDepartmentRequests) => {
            state.showAllDepartmentRequests = showAllDepartmentRequests
        },
        SET_REQUEST_ELIGIBILITY: (state, elig) => {
            state.paEligibility = elig
        },
        SET_PA_REQUEST_OVERVIEW: (state, overview) => {
          state.paRequestOverview = overview
        },
        SET_REQUEST_COMPLETION: (state, { requestCompletion }) => {
            state.paRequestCompletion = requestCompletion
        },
        SET_ATTACHED_FILES: (state, { attachedFiles }) => {
            if (attachedFiles) attachedFiles.forEach(d => deepFreeze.methods.deepFreeze(d))
            state.paAttachedFiles = attachedFiles
        },
        SET_SUGGESTED_FILES: (state, { suggestedFiles }) => {
            if (suggestedFiles) suggestedFiles.forEach(d => deepFreeze.methods.deepFreeze(d))
            state.paSuggestedFiles = suggestedFiles
        },
        SET_DOCUMENT_URLS: (state, { documentUrls }) => {
            state.documentUrls = documentUrls
        },
        SET_DOCUMENT_PREVIEWS: (state, { documentPreviews }) => {
            state.documentPreviews = documentPreviews
        },
        SET_PA_REQUEST_FORM: (state, { paRequestForm }) => {
            state.paRequestForm = paRequestForm
        },
        SET_SUBMISSION_STATUS: (state, data) => {
            state.paSubmission = data
        },
        SET_DEPARTMENT_FOLLOW_UP_DATE_RULES: (state, payload) => {
            state.departmentFollowUpDateRules = payload.departmentFollowUpDateRules;
        },
        SET_DEPT_RULES_LOADING_FLAG: (state, data) => {
            state.isRequestLoadingForDeptFollowUpRules = data;
        },
        SET_SUBMISSION_METHODS: (state, methods) => {
          state.submissionMethods = methods
        },
        SET_FACILITIES: (state, facilities) => {
            const uniqueFacilities = []
            const unique = facilities.filter(element => {
                const isDuplicate = uniqueFacilities.includes(element.name);
              
                if (!isDuplicate) {
                  uniqueFacilities.push(element.name);
              
                  return true;
                }
              
                return false;
            });
            state.facilities = unique;
        },
        SET_ASSIGNEDTO_REQUEST_COUNT: (state, payload) => {
            state.userCounts = payload;
        },
        SET_PA_SUBMISSION_HISTORY: (state, data) => {
            if (data) {
                if (!state.submissionHistory) {
                    state.submissionHistory = [];
                }

                const existingIndex = state.submissionHistory.findIndex(sub => sub.submissionId === data.submissionId);

                if (existingIndex !== -1) {
                  // if a submission is updated, we have to remove the old history and re-add it with the 
                  // new action which was preformed. Updating the element already in the array wont trigger the UI to refresh
                  // while readding it will
                  state.submissionHistory.splice(existingIndex, 1);
                }
                // Add the new object
                state.submissionHistory.push(data);
            }
            else {
                state.submissionHistory = null;
            }

        },
        SET_PA_SUBMISSION_HISTORY_WEBSOCKET_UPDATE: (state, updatedSubmission) => {
            state.submissionHistory = [...state.submissionHistory, ...updatedSubmission.historyForSubmission]
        },
        SET_PRINT_PDF_STATUS: (state, data) => {
            state.paPrintStatus = data
        },
        SET_EVENT_FEED_NOTE_CHANGES: (state, bool) => {
            state.paEventFeedNoteChanges = bool
        },
        SET_PA_EVENT_FEED: (state, { eventFeed }) => {
            state.paEventFeed = eventFeed
        },
        SET_PA_LAST_VIEW_DATE: (state, date) => {
            state.paLastViewDate = date
        },
        SET_PA_APPOINTMENT_STATUS: (state, statusName) => {
            state.paAppointmentStatus= statusName
        },
        SET_PA_EMR_DIFFERENCES: (state, differences) => {
            state.paEmrDifferences = differences
        },
        SET_PA_EVENT_FEED_LOADING: (state, status) => {
            state.paEventFeedLoading = status
        },
        SET_REQUEST_COMMENTS: (state, comments) => {
            state.requestComments = comments
        },
        SET_SHARED_PA_INFO: (state, info) => {
            state.customerSettings = info.customerSettings
            // state.providers = info.providers
            let availableProviders = []
            if(info.providers){
              availableProviders = info.providers.map(p => {
                p.fullName =  `${p.firstName}`
                if(p.lastName) p.fullName = `${p.lastName}, ${p.firstName}`
                if(p.inActive) p.fullName += ' (inactive)'
                return p
              }).filter(provider => provider.fullName !== "null")

              availableProviders.sort((a, b) => {
                return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;
              }).sort((a, b) => a.inActive - b.inActive)
            }
            availableProviders.forEach(Object.freeze)
            state.providers = availableProviders
            state.portalList = info.portals
            state.emrOrderingDepartments = info.emrOrderingDepartments
            state.servicingFacilityValues =  info.facilities.filter(f => f.name != "" && f.name != null)
            
            let payers = []
            if(info.insuranceCompanies){
              payers = info.insuranceCompanies.sort((a, b) => {
                return a.company < b.company ? -1 : a.company > b.company ? 1 : 0;
              })
            }
            payers.forEach(Object.freeze)
            state.payerList = payers
            
            state.submissionStatusesAndReasonCodes = info.submissionStatusesAndReasonCodes
            state.dataList = info.dataLists
            state.departments = info.departments
            state.stempList = info.stemp
            state.workers = info.workers
            state.currUser = info.workers.find(worker => worker.paPersonId === state.auth.currUserID)
            state.documentClasses = info.documentClasses
            state.submissionStatusCategories = info.submissionStatusCategories
            if (info.worklistTempStorage.userNotificationJsonArray) {
                state.userNotifications = JSON.parse(info.worklistTempStorage.userNotificationJsonArray)
            }
        },
        SET_PAYER_LIST: (state, payers) => {
            state.payerList = payers
        },
        SET_PA_FORMS_COMPANIES: (state, companies) => {
            state.paFormCompanies = companies
        },
        SET_HOSPITAL_NETWORK: (state, network) => {
            state.hospitalNetwork = network
        },
        SET_MODAL_DISPLAY: (state, { params }) => {
            state[params.modalType] = params.display
        },
        SET_MODAL_BLOB_DOCUMENT_ID: (state, blobDocumentId) => {
            state.modalBlobDocumentId = blobDocumentId
        },
        SET_MODAL_DATA: (state, data) => {
            state.modalData = data
        },
        SET_MODAL_COMMENTS: (state, comments) => {
            state.modalData.comments = comments
        },
        SET_PAYER_NOTES: (state, notes) => {
            state.payerNotes = notes
        },
        SET_REPORT_GROUPS: (state, reportGroups) => {
            state.reportGroups = reportGroups
        },
        SET_REPORT_LIST: (state, reportList) => {
            state.reportList = reportList
        },
        SET_REPORT: (state, report) => {
            state.reportHTML = report
        },
        SET_LAST_ACTIVE_REPORTS_TAB: (state, lastActiveReportsTab) => {
            state.lastActiveReportsTab = lastActiveReportsTab
        },
        SET_GLOBAL_MESSAGE: (state, message) => {
            state.globalMessage.type = message[0]
            state.globalMessage.message = message[1]
            state.globalMessage.timeout = message[2]
            state.globalMessage.destination = message[3]
        },
        SET_WORKLIST_SORT: (state, worklistSortInfo) => {
            state.worklistSortInfo = worklistSortInfo
        },
        SET_ARCHIVE_SORT: (state, archiveSortInfo) => {
            state.archiveSortInfo = archiveSortInfo
        },
        SET_FALLBACK_TO_POLLING: (state, bool) => {
            state.fallbackToPolling = bool
        },
        SET_USER_PROFILE_CHANGES: (state, bool) => {
            state.userProfileChanges = bool
        },
        SET_USER_CREDENTIAL_CHANGES: (state, bool) => {
            state.userCredentialChanges = bool
        },
        SET_PORTAL_ASSISTANT_DISPLAY: (state, params) => {
            state.portalAssistant = params;
        },
        SET_DIRECT_SUBMIT_WINDOW_DISPLAY: (state, params) => {
            state.directSubmitWindow = params;
        },
        SET_DIRECT_SUBMIT_WINDOW_MINIMIZED: (state, bool) => {
            state.directSubmitWindow.minimized = bool;
        },
        SET_CAN_VIEW_PCP_FORMS: (state, bool) => {
            state.canViewPcpForms = bool;
        },

    },
    getters: {
        hiddenStatuesFromWorklist: state => {
            return state.hiddenStatuesFromWorklist;
        },
        userSettableSubmissionStatuses: state => {
            if (!state.submissionStatusesAndReasonCodes) return [];
            return state.submissionStatusesAndReasonCodes.statuses.filter( status => status.userSettable );
        },
        allValidUsers: state => {
            //A valid user is one that follows the below criteria
            return state.workers.filter(w => w.isValid && w.paPersonId < 100000 && !w.listOfRoles.includes('ReadOnly') && !w.listOfRoles.includes('OliveSupport'))
        },
        requestCounts: state => {
          let reducer = function(acc, request){
            if( request.assignedToId === null ){
              acc.unassigned = acc.unassigned + 1
            }
            if( request.assignedToId === null && ( request.status === 10 || request.status === 30 ) ){
              acc.unassignedNew = acc.unassignedNew + 1
            }
            if( request.assignedToId === state.currUser.paPersonId && (request.status === 10 || request.status === 30) ){
              acc.currentUserNew = acc.currentUserNew + 1
            }
            if( request.assignedToId === state.currUser.paPersonId && (request.urgency === 10 || request.urgency === 5) ){
              acc.currentUserUrgent = acc.currentUserUrgent + 1
            }
            
            if(!acc.userCounts[request.assignedToId]){
              acc.userCounts[request.assignedToId] = 1
            } else {
              acc.userCounts[request.assignedToId] += 1
            }
            
            return acc
          }
          let current = {
            unassigned:0,
            unassignedNew:0,
            currentUserNew:0,
            currentUserUrgent: 0,
            userCounts: {}
          }
          return state.requests.reduce(reducer, current)
        },
        userRole: state => {
            var roles
            if (state.currUser.listOfRoles || state.currUser.listOfRoles === '') {
                roles = state.currUser.listOfRoles.split(', ')
            }
            return roles
        },
        readOnlyUser: state => {
            if (state.currUser.listOfRoles || state.currUser.listOfRoles === '') {
                let rolesList = state.currUser.listOfRoles.split(', ')
                if (!rolesList.includes('ReadOnly')) {
                    return false
                }
            }
            return true
        },
        userName: state => {
            return state.currUser.firstName + ' ' + state.currUser.lastName;
        },
        userOrg: state => {
            let worker = state.workers.find(w => {
                return w.paPersonId === state.currUser.paPersonId
            })
            if (worker) return worker.organization
            return ''
        },
        statusList: state => {
            const statuses = state.dataList.Status.reduce((acc, s, i) => {
                acc[s.Id] = s.PrettyName
                return acc
            }, {})

            return statuses
        },
        sortedPortalList: state => {
          if(!state.portalList) return false
          
          let otherPortal = state.portalList.find( p => p.id === 9999)
          otherPortal.description = "Portal Other"
          otherPortal.displayName = "Portal Other"
          let portals = state.portalList.filter( p => p.id !== 9999)

          let sortedPortals = portals.sort((a, b) => {
              return a.description > b.description ? 1 : -1;
          })

          return [...sortedPortals, otherPortal]
        },
        portalStatusCheckList: (state, getters) => {
            if(!state.portalList) return [];
            return getters.sortedPortalList.filter( p => p.supportsStatusChecks);
        },
        urgencyList: state => {
            return state.dataList.UrgentEnum.map(ue => (
                new UrgencyType({
                    code: ue.Value,
                    displayName: ue.Name
                })
            ))
        },
        disabledWorkers: state => {
          let disabled = state.workers.filter(w => !w.isValid && w.paPersonId < 100000)
          return disabled
        },
        assignees: (state, getters) => {
            let assignees = getters.allValidUsers;
            let roles = []

            assignees = assignees.sort((a, b) => {
                return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;
            }).sort((a, b) => {
                return a.departmentId < b.departmentId ? -1 : a.departmentId > b.departmentId ? 1 : 0;
            })

            if (state.currUser.listOfRoles) {
                roles = state.currUser.listOfRoles.split(', ')
            }

            if (!roles.includes('Manager')) {
                assignees = [state.currUser]
            }

            return assignees
        },
        otherWorkerIds: (state, getters) => {
            if(getters.currUserDeptWorkers) return getters.currUserDeptWorkers.filter( w => w.paPersonId !== state.currUser.paPersonId ).map(w => w.paPersonId)
            return []
        },
        currUserDeptWorkers: (state, getters) => {
            if (!state.departments || !state.currUser) return false
            let assignees = getters.allValidUsers
            assignees = assignees.sort((a, b) => {
                return a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : 0;
            })
            if(state.currUser.canViewAllDepartments) return assignees
            assignees = assignees.filter(a => a.departmentIds.some(id => state.currUser.departmentIds.includes(id)) || a.canViewAllDepartments)
            return assignees
        },
        activeWorkers: state => {
            if (state.workers){
            //add in fullUserName
                let workers = state.workers
                for (let i = 0; i < workers.length; i++){
                    workers[i].fullUserName = workers[i].firstName + ' ' + workers[i].lastName;
                }
                return workers.filter(x => x.isValid);
            }
            return [];
        },
        // Used to generate list of assignees grouped by their dept
        assigneesByDepartment: (state, getters) => {
            if(!state.departments) return false
            let assignees = getters.allValidUsers;
            assignees = assignees.sort((a, b) => {
                return a.lastName.toLowerCase() < b.lastName.toLowerCase() ? -1 : a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : 0;
            })

            let reducer = function (departments, worker) {
                if (worker.canViewAllDepartments) { // If user can view all depts add to every dept
                    departments.forEach( d => d.assignees.push(worker))
                } else {
                    worker.departmentIds.forEach( dId => { // user cant view all depts add to list of dept ids
                        let cannotViewAllDepartments = departments.find(d => d.departmentId == dId);
                        
                        if(cannotViewAllDepartments) {
                            cannotViewAllDepartments.assignees.push(worker)
                        }
                        return cannotViewAllDepartments;
                    })
                }
                return departments
            }
            let current = state.departments.map( d => {
                d.assignees = []
                return d
            })
            return assignees.reduce(reducer, [...current])
        },
        assigneesWithDepartments: (state, getters) => {
            let assignees = getters.allValidUsers;
            let roles = []

            assignees = assignees.sort((a, b) => {
                return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;
            }).sort((a, b) => {
                return a.departmentId < b.departmentId ? -1 : a.departmentId > b.departmentId ? 1 : 0;
            })

            if (state.currUser.listOfRoles) {
                roles = state.currUser.listOfRoles.split(', ')
            }

            if (!roles.includes('Manager')) {
                return [state.currUser]
            }

            let sortedAssignees = assignees.sort((a, b) => {
                return a.departmentId < b.departmentId ? -1 : a.departmentId > b.departmentId ? 1 : 0;
            })

            let currDept = -1;
            let appendedWorkers = []
            for (let i = 0; i < sortedAssignees.length; i++) {
                // Check if this worker is in a different department
                if (sortedAssignees[i].departmentId > currDept) {
                    // Add disabled worker as divider in select list
                    appendedWorkers.push({ departmentName: `– ${sortedAssignees[i].departmentName} –`, disabled: true, paPersonId: -1 })
                        // set department flag to this workers department
                    currDept = sortedAssignees[i].departmentId
                }
                appendedWorkers.push(sortedAssignees[i])
            }
            return appendedWorkers
        },
        availableDepartmentsForTheCurrentUser: state => {
            const departmentsTheCurrentUsersCanView = state.currUser && state.currUser.canViewAllDepartments ? [] : state.currUser.departmentIds;

            if (departmentsTheCurrentUsersCanView && departmentsTheCurrentUsersCanView.length > 0) {
                return state.departments.filter(department => departmentsTheCurrentUsersCanView.includes(department.departmentId))
            }

            return state.departments.filter(department => department.isDepartmentActive);
        },
        worklistParams: state => {
            const resultsPerPage = state.worklistDisplaySettings && state.worklistDisplaySettings.worklistRequestsPerPage ? state.worklistDisplaySettings.worklistRequestsPerPage : 10
            
            // This value will be used for the "Skip" parameter when iterating over requests. If this is negative, set it to 0
            var skipOffset = state.worklistCurrentPage - 1;
            if ( skipOffset < 0 ) {
                skipOffset = 0;
            }
            
            return {
                "Filter": {
                    "Logic": null,
                    "Filters": [
                        ...(
                                state.worklistCurrentFilters && state.worklistCurrentFilters.length ? 
                                state.worklistCurrentFilters.filter(worklistFilter => {
                                    if (
                                        worklistFilter.Field === 'mrn' ||
                                        worklistFilter.Field === 'firstName' ||
                                        worklistFilter.Field === 'lastName' ||
                                        worklistFilter.Field === 'strOrderNumber' ||
                                        worklistFilter.Field === 'cptCodes.CptCode'
                                    ) {
                                        return worklistFilter.Value !== ""
                                    }

                                    return true
                                }) 
                                    :
                                []
                            ),
                    ],
                    "Modifiers": state.worklist.filterUpdates
                },
                "Sort": state.worklistSortInfo
                .map(sortInfo => {
                    return {
                        "Field": sortInfo.field,
                        "Dir": sortInfo.direction < 0 ? 'DESC' : 'ASC'
                    }
                }),
                "SearchTerm": state.textSearchTerm || null,
                "Take": resultsPerPage,
                "Skip": (skipOffset) * resultsPerPage
            }
        },
        archiveParams: state => {
            const resultsPerPage = state.worklistDisplaySettings && state.worklistDisplaySettings.worklistRequestsPerPage ? state.worklistDisplaySettings.worklistRequestsPerPage : 10

            return {
                "Filter": {
                    "Logic": null,
                    "Filters": state.worklistCurrentFilters && state.worklistCurrentFilters.length ? 
                                    state.worklistCurrentFilters.filter(worklistFilter => {
                                        if (
                                            worklistFilter.Field === 'mrn' ||
                                            worklistFilter.Field === 'firstName' ||
                                            worklistFilter.Field === 'lastName' ||
                                            worklistFilter.Field === 'strOrderNumber' ||
                                            worklistFilter.Field === 'cptCodes.CptCode'
                                        ) {
                                            return worklistFilter.Value !== ""
                                        }

                                        return true
                                    })
                                        : 
                                    []
                },
                "Sort": state.archiveSortInfo
                .map(sortInfo => {
                    return {
                        "Field": sortInfo.field,
                        "Dir": sortInfo.direction < 0 ? 'DESC' : 'ASC'
                    }
                }),
                "SearchTerm": null,
                "Take": resultsPerPage,
                "Skip": (state.archiveCurrentPage - 1) * resultsPerPage
            }
        },
        getPrimaryDiagnosis: state => {
            if (state?.paRequestAndFormData?.medicalFormData?.primaryDiagnosis || state?.paRequestAndFormData?.medicalFormData?.primaryDiagnosis == '') {
                return state.paRequestAndFormData.medicalFormData.primaryDiagnosis
            }

            return null
        },
        requestHasCptCodes: state => {
            return state?.paRequestAndFormData?.medicalFormData?.cpT_Codes?.length
        },
        getInitialCptCode: state => {
            return state?.paRequestAndFormData?.medicalFormData?.cpT_Codes[0]?.code
        },
        currentUserIsManager: state => {
            return state?.currUser?.listOfRoles?.split(', ')?.includes('Manager')
        },
        getPaRequestId: state => {
            return state?.paRequestAndFormData;
        },
        getPaRequestOverview: state => {
            return state?.paRequestOverview;
        }
    }
})
export default store