import axios from "axios"
import ENV_CONFIG from '@/config/environment'
import deepFreeze from '@/mixins/deepFreeze'
import { REQUIREMENT_ID_TYPES } from '@/shared/constants/medicalNecessity'

const apiPath1 = ENV_CONFIG.env_api;

function initialState() {
    return {
        medicalNecessitiesTabEnabled: false,
        requestRequirementsResults: null,
        medicalNecessityStatus: null,
        requestRequirements: null,
        requirementLists: null,
        updatingRequirementResults: [],
        requestRequirementsErr: false,
        requirementConfidenceThresholds: false,
        paRequestRequirementsInfo: false,
        requirementNotes: [],
        requirementUpdateQueue: [],
        noteUpdatingQueue: [],
        bulkUpdateInfo: null,
        requirementsInfo: [],
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        SET_REQUIREMENT_NOTE: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}RequirementResults/AddNote`, params, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    commit('SET_REQUIREMENT_NOTES', response.data.notes)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', `${err}, unable to add note`], { root: true })
                    window.console.log(err)
                })
        },
        GET_REQUEST_REQUIREMENTS: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}PaRequirements/GetRequirements`, params, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    if(response.data.requirementLists) {
                      commit('SET_REQUEST_REQUIREMENTS', response.data)
                      commit('SET_REQUEST_REQUIREMENTS_RESULTS', response.data.requirementResults)
                      commit('SET_PA_REQUIREMENTS_INFO', response.data.paRequestRequirementsInfo)
                      commit('SET_REQUIREMENT_NOTES', response.data.paRequestRequirementsInfo.notes)
                      commit('SET_REQUEST_REQUIREMENTS_LIST', response.data.requirementLists)
                    }

                    if(!response.data.requirementLists) commit('SET_REQUEST_REQUIREMENTS_ERR', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_REQUEST_REQUIREMENTS_ERR', err.response.data)
                    return err;
                })
        },
        CLEAR_REQUEST_REQUIREMENTS: function({ commit }) {
            commit('SET_REQUEST_REQUIREMENTS', false)
        },
        GET_REQUEST_REQUIREMENTS_RESULTS: async function({ commit, dispatch }, params) {
            await axios.get(`${apiPath1}RequirementResults/GetRequirementResultsForPa/${params.paRequestId}/${params.insuranceCompanyId}/${params.stempCode}/${params.inPatientStatus}/${params.insurancePlanId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_REQUIREMENTS_RESULTS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        SET_REQUEST_REQUIREMENT_RESULT: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}RequirementResults/${params.resultState}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', `${err}, unable to set requirement`])
                    window.console.log(err)
                })
        },
        CLEAR_REQUEST_REQUIREMENTS_RESULTS: function({ commit }) {
            commit('SET_REQUEST_REQUIREMENTS_RESULTS', false)
        },
        UPDATE_REQUIREMENT_RESULTS: function({ commit }, updatingRequirementResults) {
            commit('SET_UPDATING_REQUIREMENT_RESULTS', updatingRequirementResults)
        },
        GET_REQ_CONFIDENCE_THRESHOLDS: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}RequirementResults/GetConfidenceThresholds`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQ_CONFIDENCE_THRESHOLDS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        ADD_ACTION_TO_QUEUE: function({ commit }, params) {
            commit('UPDATE_REQUIREMENTS_QUEUE', params)
        },
        CLEAR_ACTION_QUEUE: function({ commit }) {
            commit('UPDATE_REQUIREMENTS_QUEUE', [])
        },
        SET_REQUEST_REQUIREMENTS_RESULTS: function ({ commit }, requestRequirementsResults) {
            commit('SET_REQUEST_REQUIREMENTS_RESULTS', requestRequirementsResults)
        },
        SET_REQUEST_REQUIREMENTS_LIST: function ({ commit }, requirementList) {
            commit('SET_REQUEST_REQUIREMENTS_LIST', requirementList)
        },
        CLEAR_UPDATING_REQUIREMENT_RESULTS: function ({ commit }) {
            commit('SET_UPDATING_REQUIREMENT_RESULTS', [])
        },
        CLEAR_NOTE_TO_NOTE_UPDATING_QUEUE: function ({ commit }) {
            commit('SET_NOTE_TO_NOTE_UPDATING_QUEUE', [])
        },
        ADD_NOTE_TO_NOTE_UPDATING_QUEUE: function ({ commit }, note) {
            commit('SET_NOTE_TO_NOTE_UPDATING_QUEUE', note)
        },
        POST_BULK_UPDATE_REQUIREMENT_RESULTS: async function ({ dispatch, commit }, payload) {
            await axios.post(`${apiPath1}RequirementResults/BulkUpdateRequirementResults`, {
                ...payload
            }, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_UPDATING_REQUIREMENT_RESULTS', [])
                commit('SET_NOTE_TO_NOTE_UPDATING_QUEUE', [])
            }, (err) => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                window.console.log(err)
                return err;
            })
        },
        SET_BULK_UPDATE_INFO: function ({ commit }, bulkUpdateInfo) {
            commit('SET_BULK_UPDATE_INFO', bulkUpdateInfo)
        },
        SET_REQUIREMENTS_INFO: function ({ commit }, requirementsInfo) {
            commit('SET_REQUIREMENTS_INFO', requirementsInfo)
        },
        UPDATE_REQUIREMENTS_INFO: function ({ commit, state }, updatedRequirementsInfo) {
            const wasPreviouslySaved = state.requirementsInfo.find(
                requirementsInfo => (
                    requirementsInfo.paRequestId === updatedRequirementsInfo.paRequestId &&
                    requirementsInfo.paRequirementsId === updatedRequirementsInfo.paRequirementsId
                )
            )

            let tmpRequirementsInfo = []
            
            if (wasPreviouslySaved) {
                tmpRequirementsInfo = state.requirementsInfo.map(
                    requirementsInfo => {
                        if (
                            requirementsInfo.paRequestId === updatedRequirementsInfo.paRequestId &&
                            requirementsInfo.paRequirementsId === updatedRequirementsInfo.paRequirementsId
                        ) {
                            return {
                                ...requirementsInfo,
                                ...updatedRequirementsInfo
                            }
                        }
    
                        return requirementsInfo
                    }
                )
            } else {
                const tempArray = [...state.requirementsInfo]
                tempArray.push(updatedRequirementsInfo)

                tmpRequirementsInfo = [...tempArray]
            }

            commit('SET_REQUIREMENTS_INFO', tmpRequirementsInfo)
        }
    },
    mutations: {
        SET_DISPLAY_TAB: function (state, enableTab) {
            state.medicalNecessitiesTabEnabled = enableTab;
        },
        SET_REQUIREMENTS_INFO: function (state, requirementsInfo) {
            state.requirementsInfo = requirementsInfo
        },
        SET_BULK_UPDATE_INFO: function (state, bulkUpdateInfo) {
            state.bulkUpdateInfo = bulkUpdateInfo
        },
        SET_NOTE_TO_NOTE_UPDATING_QUEUE: (state, note) => {
            if (note) {
                note.forEach(Object.freeze)
            }
            state.noteUpdatingQueue = note
        },
        UPDATE_REQUIREMENTS_QUEUE: (state, requirementUpdateQueue) => {
            state.requirementUpdateQueue = requirementUpdateQueue
        },
        SET_PA_REQUIREMENTS_INFO: (state, paRequestRequirementsInfo) => {
            state.paRequestRequirementsInfo = paRequestRequirementsInfo
        },
        SET_REQUEST_REQUIREMENTS_LIST: (state, requirementList) => {
            state.requirementLists = requirementList
        },
        SET_REQUIREMENT_NOTES: (state, notes) => {
            state.requirementNotes = Object.freeze(notes || [])
        },
        SET_REQUEST_REQUIREMENTS: (state, requestRequirements) => {
            if (requestRequirements) {
                deepFreeze.methods.deepFreeze(requestRequirements)
            }

            state.requestRequirements = requestRequirements
        },
        SET_REQUEST_REQUIREMENTS_RESULTS: (state, requestRequirementsResults) => {
            if (requestRequirementsResults) {
                requestRequirementsResults.forEach(Object.freeze)
            }
            state.requestRequirementsResults = requestRequirementsResults
        },
        SET_UPDATING_REQUIREMENT_RESULTS: (state, updatingRequirementResults) => {
            if (updatingRequirementResults) {
                updatingRequirementResults.forEach(Object.freeze)
            }

            state.updatingRequirementResults = updatingRequirementResults
        },
        SET_REQUEST_REQUIREMENTS_ERR: (state, err) => {
            state.requestRequirementsErr = err
        },
        SET_REQ_CONFIDENCE_THRESHOLDS: (state, thresholds) => {
            state.requirementConfidenceThresholds = thresholds
        },
    },
    getters: {
        getOverallRequirementNote: (state) => {
            return state.requirementNotes.find(
                note => note.idType === REQUIREMENT_ID_TYPES.PA_REQUIREMENT
            ) || { note: '' }
        },
        getPolicyUrlDocumentNames: (state) => {
            if(state?.requestRequirements && state?.requestRequirements?.policyUrls?.length){
                return state.requestRequirements.policyUrls.map( url => {
                    let splitOnSlash = url.split('/')
                    return  {
                        name: decodeURI(splitOnSlash.splice(-1)[0]) || 'Document',
                        url: url
                    }
                })
            }
            return false
        },
        hasRequestDocumentsToDisplay: (state) => {
            return state.requestRequirements && state.requestRequirementsResults
        },
        getRequestRequirementsResults: (state) => {
            const replacedState = (state.requestRequirementsResults || []).map(requestRequirementResult => {
                const found = (state.updatingRequirementResults || []).find(
                    urr => urr.paRequirementsId === requestRequirementResult.paRequirementsId
                )

                if (found) {
                    return found
                }

                return requestRequirementResult
            })

            const newUpdatedRequirements = (state.updatingRequirementResults || []).filter(updatingRequirementResult => {
                const found = (state.requestRequirementsResults || []).find(
                    rrr => rrr.paRequirementsId === updatingRequirementResult.paRequirementsId
                )

                return !found
            })

            return [...replacedState, ...newUpdatedRequirements]
        },
        medicalNecessityStatus: (state) => {
            if (state.requirementsInfo.length === 0) {
                return 'unknown'
            } else {
                const hasAllUnknownStatus = state.requirementsInfo.every(
                    requirementsInfo => requirementsInfo.requirementsMet === null
                )

                if (hasAllUnknownStatus) {
                    return 'unknown'
                }

                const hasSomeOverallRequirementsMet = state.requirementsInfo.some(
                    requirementsInfo => requirementsInfo.requirementsMet
                )

                const hasSomeOverallRequirementsNotMet = state.requirementsInfo.some(
                    requirementsInfo => requirementsInfo.requirementsMet === false
                )


                if (hasSomeOverallRequirementsMet && !hasSomeOverallRequirementsNotMet) {
                    return 'requirements_met'
                }

                return 'requirements_not_met'
            }
        },
        medicalNecessitiesTabEnabled: (state) => {
            return state.medicalNecessitiesTabEnabled;
        }
    }
}