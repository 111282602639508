import axios from 'axios'

import ENV_CONFIG from '@/config/environment'
const API = ENV_CONFIG.env_api

export class ReportApiService {

    static dataExport(params) {
        return axios.post(`${API}Report/DataExport`,
          {
            ...params
          },
          {
            headers: {
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
              'Content-Disposition': "attachment; filename=template.xlsx",
            },
            responseType: 'arraybuffer',
          })
    }

    static async getStatus(id) {
        return await axios.get(`${API}Report/GetStatus/${id}`, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("token") }
        });
    }
}