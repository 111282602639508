<template>
  <div class="pageFooter">
    <div v-once class="info">build: {{ showBuildNum() }}</div>
    <div class="support">
      <span v-if="!loggedIn">
        Get Help:
        <a
          href="mailto:customersupport@humatahealth.com?subject=Pathway Login Page Issue&body=***This email is not secure, and including any specific patient information may violate various laws and regulations.***
%0D%0A***Please do not include any patient info, PHI or PII, in this message.  If needed, Medical Record Number(s) or MRN(s) can be included, but no other information that could be used to identify any patient.  Thank you.***
"
          >Email Support</a
        ></span
      >
      <span v-if="loggedIn && currUser.organization"
        >Get Help:
        <a href="#" @click.prevent="$parent.toggleContactSupportModal('an issue')"
          >Contact Support</a
        >
        |
        <a href="#" @click.prevent="$parent.toggleReleaseNoteDisplay"
          >Release Notes</a
        >
        |
        <a href="#" target="_blank" @click.prevent="$parent.downloadUserGuide"
          >User Guide</a
        >
        |
        <a href="#" @click.prevent="$parent.toggleContactSupportModal('training')"
          >Training</a
        >
        </span
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ENV_CONFIG from "@/config/environment";

export default {
  name: "SiteFooter",
  data: function () {
    return {
      gitDescribe: VERSION,
      commit: COMMITHASH,
      branch: BRANCH,
      azureBuildNum: BUILDNUMBER,
    };
  },
  computed: {
    ...mapState(["currUser"]),
    ...mapState('auth', ['loggedIn']),
    trainingLink: function () {
      return `${ENV_CONFIG.training_url}?token=${sessionStorage.getItem("token")}`
    },
  },
  methods: {
    showBuildNum: function () {
      const envName = ENV_CONFIG.env_name;
      var n = "";
      if (envName != "Production") {
        n =
          this.azureBuildNum +
          " // commit: " +
          this.commit.substring(0, 6) +
          " // env: " +
          envName;
      } else {
        n = this.azureBuildNum;
      }
      return n;
    },
  },
};
</script>
